<template>
  <div class="box-summary-payment">
    <data-summary-payment
      v-if="dataResponse != {}"
      :data-response="dataResponse"
      :products="products"
    />
  </div>
</template>
<script>
import DataSummaryPayment from '@/components/Payment/DataSummaryPayment.vue'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'PedidoDetalle',
  components: { DataSummaryPayment },
  data() {
    return {
      dataResponse: null,
      products: [],
    }
  },
  computed: {
    ...mapState('', []),
    getDataProductsCRTO() {
      let arrayTemp = []
      this.dataResponse.productos.forEach(product => {
        arrayTemp.push({
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
  async mounted() {
    this.changeMenu({ menu: 'menu-simple' })
    this.changeFooter({ footer: 'footer-simple' })
    this.$store.state.loaderDerek = true
    if (location.search) {
      await this.axios({
        method: 'GET',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/payu/response' + location.search,
      })
        .then(response => {
          this.$store.state.loaderDerek = false
          if (response.data.response.carrito_cerrado == 0) {
            if (response.data.response.checkoutAnonimo == 1) {
              this.$router
                .replace({
                  name: 'PaymentAnonymous',
                  params: {
                    tokenCart: response.data.response.carrito.token,
                    showmodalPaymentDecline: true,
                  },
                })
                .catch(() => {})
            } else {
              this.$router
                .replace({
                  name: 'Payment',
                  params: { showmodalPaymentDecline: true },
                })
                .catch(() => {})
            }
          } else {
            // if (response.data.response.visita == 0) {
            //   // this.$gtag.event('add_payment_info', {
            //   //   coupon: this.dataSummaryPayment.cupon,
            //   //   currency: 'COP',
            //   //   items: this.cart,
            //   //   paymentType: 'Efecty',
            //   //   value: this.dataSummaryPayment.carrito_subtotal,
            //   // })
            //   // this.$gtag.event('checkoutOption', {
            //   //   event: 'checkoutOption',
            //   //   ecommerce: {
            //   //     checkout_option: {
            //   //       actionField: {
            //   //         step: 3,
            //   //         option: this.dataSummaryPayment.pedido.pedido.transaccion
            //   //           .transaccion_metodopago,
            //   //       },
            //   //     },
            //   //   },
            //   // })
            //   // this.$gtag.event('transaction', {
            //   //   event: 'transaction',
            //   //   ecommerce: {
            //   //     purchase: {
            //   //       actionField: {
            //   //         id: this.dataSummaryPayment.pedido.carrito.reference_sale,
            //   //         affiliation: 'Compra Online Derek.co',
            //   //         revenue: this.dataSummaryPayment.pedido.carrito
            //   //           .carrito_total,
            //   //         tax: 0,
            //   //         shipping: this.dataSummaryPayment.pedido.carrito
            //   //           .carrito_envio,
            //   //         coupon: this.dataSummaryPayment.pedido.carrito
            //   //           .cupon_codigo,
            //   //       },
            //   //       products: this.getDataAnalytics,
            //   //     },
            //   //   },
            //   // })
            //   // // *Evento Criteo*
            //   // this.$gtag.event('crto_transactionpage', {
            //   //   event: 'crto_transactionpage',
            //   //   crto: {
            //   //     email: this.dataSummaryPayment.pedido.carrito.cliente_correo,
            //   //     transactionid: this.dataSummaryPayment.pedido.carrito
            //   //       .reference_sale,
            //   //     products: this.getDataProductsCRTO,
            //   //   },
            //   // })
            //   var deviceType = /iPad/.test(navigator.userAgent)
            //     ? 't'
            //     : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
            //         navigator.userAgent,
            //       )
            //     ? 'm'
            //     : 'd'
            //   window.criteo_q.push(
            //     { event: 'setAccount', account: 67539 },
            //     {
            //       event: 'setEmail',
            //       email: this.dataSummaryPayment.pedido.carrito.cliente_correo,
            //     },
            //     { event: 'setSiteType', type: deviceType },
            //     { event: 'setZipcode', zipcode: '##Zip Code##' },
            //     {
            //       event: 'trackTransaction',
            //       id: this.dataSummaryPayment.pedido.carrito.reference_sale,
            //       item: this.getDataProductsCRTO,
            //     },
            //   )
            //   this.$emit('nextStep')
            // }
            this.dataResponse = response.data.response.pedido
            this.products = response.data.response.pedido.productos
          }
        })
        .catch(() => {
          this.$store.state.loaderDerek = false
          this.$router.replace({ name: 'Home' }).catch(() => {})
        })
      // this.$gtag.pageview(this.$route.fullPath)
    } else {
      this.$router.push({ name: 'Home' })
    }
  },
  destroyed() {
    this.changeMenu({ menu: 'menu-base' })
    this.changeFooter({ footer: 'footer-derek' })
  },
  methods: {
    ...mapActions('layouts', ['changeMenu', 'changeFooter']),
    goToCuponPago() {
      location.href = this.dataResponse.linkTicketPago
    },
  },
}
</script>
<style scope>
.divider-product {
  height: 1px;
  background: #eaf0fc;
  margin-left: 1rem !important;
  max-width: 90% !important;
}
.sub-title {
  font-size: 18px;
}
</style>
