<template>
  <div>
    <b-col v-if="!sended" class="container-raiting mb-5">
      <div class="container-raiting-box px-0">
        <h6 class="text-center my-4">
          ¿Qué probabilidades hay de que recomiende Derek a un amiga?
        </h6>
        <div class="rating-order">
          <div class="d-none d-md-block">
            <span class="w-100 pe-2">😢</span>
            <button
              v-for="(item, index) in 11"
              :key="index + item"
              :class="range == index ? 'selected' : ''"
              class="btn-rating-order-summary"
              @click="range = index"
            >
              {{ index }}
            </button>
            <span class="w-100">🤩</span>
            <div class="p-n-probable col-12 col-md-11">
              <p>Nada probable</p>
              <p>Muy probable</p>
            </div>
          </div>
          <div class="d-flex flex-wrap d-md-none my-5">
            <div class="w-100 d-flex justify-content-between mb-4">
              <span>😢</span>
              <span>🤩</span>
            </div>
            <div class="w-100 position-relative">
              <input
                id="range-slider"
                v-model="range"
                type="range"
                list="tickmarks"
                min="0"
                max="10"
              />
              <label class="value-range" :style="valueRatingMovil">
                {{ range }}
              </label>
            </div>
          </div>
          <div v-if="range">
            <h6 class="text-center my-4">
              ¿Qué podemos hacer para sorprenderte?
            </h6>
            <div>
              <textarea
                v-model="text"
                rows="5"
                class="text-area-order-summary"
                placeholder="¿Qué mejorarías de nuestro sitio?"
              ></textarea>
            </div>
            <div
              class="d-flex mb-3 justify-content-center col-12 col-md-8 col-xl-7 px-0 px-md-2 px-xl-3 mx-auto"
            >
              <button
                class="w-100 btn-primary btn-finalize-payment"
                style="max-width: 450px"
                @click="registerPoll()"
              >
                Enviar comentario
              </button>
            </div>
          </div>
          <div>
            <span v-if="errorEncuesta" class="msgError"
              >El puntaje es requerido</span
            >
            <span v-if="errorSurvey" class="msgError"
              >No se pudo registrar la encuesta</span
            >
          </div>
        </div>
      </div>
    </b-col>
    <div v-else class="container-raiting mb-5">
      <h4 class="mb-0">
        Gracias por tu opinión
      </h4>
    </div>
    <div class="d-flex justify-content-center">
      <button
        class="btn-primary col-12 col-md-6 col-lg-4 col-xl-3"
        @click="clearDataAndBack()"
      >
        Volver a la tienda
      </button>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    reference: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      text: '',
      range: null,
      sended: false,
      message: '',
      styleRange: [
        'left: 4%;',
        'left: 13.5%;',
        'left: 22%;',
        'left: 31%;',
        'left: 40%;',
        'left: 49%;',
        'left: 57.5%;',
        'left: 66%;',
        'left: 75%;',
        'left: 84%;',
        'left: 91.5%;',
      ],
    }
  },
  computed: {
    ...mapState('cart', ['dataSummaryPayment', 'errorSurvey', 'productsCart']),
    valueRatingMovil() {
      return this.styleRange[this.range]
    },
  },
  methods: {
    ...mapActions('cart', ['RegisterPollCheckout']),
    async registerPoll() {
      this.errorEncuesta = false
      if (this.range) {
        await this.RegisterPollCheckout({
          pedido_id: this.reference,
          puntaje: this.range,
          mensaje: this.text,
        }).then(() => {
          if (!this.errorSurvey) {
            this.sended = true
          }
        })
      } else {
        this.errorEncuesta = true
      }
    },
    clearDataAndBack() {
      this.$store.state.cart.cart = []
      this.$router.replace({ name: 'Home' })
      localStorage.removeItem('cartTokken')
    },
  },
}
</script>
<style>
.container-raiting {
  max-width: 600px;
  background-color: #f8f8f8;
  border-radius: 5px;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.container-raiting-box {
  max-width: 600px;
}
.container-raiting-box #summary-survey {
  display: flex;
  align-items: center;
}
.container-raiting-box #summary-survey .btn-secondary {
  background-color: transparent;
  border: 1px solid #001f5d;
  color: #001f5d;
  margin-bottom: 0;
  width: 40px;
  height: 40px;
  margin-right: 0.3rem;
  background-color: #eaf0fc !important;
}
.container-raiting-box #summary-survey span {
  font-size: 26px;
}
.container-raiting-box #summary-survey .btn-secondary.active,
.container-raiting-box #summary-survey .btn-secondary:hover {
  background-color: #001f5d !important;
  border-color: #001f5d;
  color: white;
  font-weight: bold;
}
</style>
