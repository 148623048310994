<template>
  <div class="container-summary-payment">
    <b-col
      v-if="dataResponse != null"
      class="py-4 mx-auto justify-center col-11 col-md-10 col-xl-9 px-0"
    >
      <div class="d-flex justify-content-center mb-4">
        <img src="@/assets/icons/success-summary.svg" class="mx-auto" />
      </div>
      <div class="d-flex mb-4">
        <p class="container-no-pedido">
          Pedido No. {{ dataResponse.pedido.pedido_id }}
        </p>
      </div>
      <h3 class="text-center mb-3">
        ¡Gracias por tu compra!
      </h3>
      <p class="text-small mx-auto mb-4" style="max-width: 536px">
        Tu pedido ha sido recibido, muy pronto recibirás un correo electrónico
        con todos los detalles de tu compra.
      </p>
      <div class="container-stados-summary-payment">
        <div class="container-step container-step-select">
          <img
            src="@/assets/icons/icon-step-1-summary-payment.svg"
            class="mx-auto"
          />
        </div>
        <div
          class="container-step"
          :class="dataResponse.pedido.status ? 'container-step-select' : ''"
        >
          <img
            src="@/assets/icons/icon-step-2-summary-payment.svg"
            class="mx-auto"
          />
        </div>
        <div class="container-step">
          <img
            src="@/assets/icons/icon-step-3-summary-payment.svg"
            class="mx-auto"
          />
        </div>
        <div class="container-step">
          <img
            src="@/assets/icons/icon-step-4-summary-payment.svg"
            class="mx-auto"
          />
        </div>
        <div class="container-name-step ">
          <div class="name-step name-step-select">
            Pedido
            <br />recibido
          </div>
          <div
            class="name-step"
            :class="dataResponse.pedido.status ? 'name-step-select' : ''"
          >
            Pago
            <br />confirmado
          </div>
          <div class="name-step">
            Pedido
            <br />facturado
          </div>
          <div class="name-step">
            Pedido
            <br />enviado
          </div>
        </div>
      </div>
      <div
        v-if="dataResponse.pedido.transaccion.transaccion_urlefectybaloto"
        class="container-info-data mb-4"
        style="background-color: #fbeee3"
      >
        <h5 class="text-center mb-3">
          <b>Resumen de tu pago</b>
        </h5>
        <p class="mx-auto text-center" style="max-width: 786px">
          Realizaste tu compra con Efecty, no demores en pagar, solo podemos
          reservarte los productos cuando el pago se acredite. Tu pedido será
          cancelado si no realizas el pago antes del
          <b>{{ dataResponse.pedido.transaccion.fecha_vencimiento }}</b>
        </p>
        <div class="mb-3 mt-4 d-flex justify-content-center">
          <button class="btn-primary col-md-4 col-xl-3">
            <a
              target="_blank"
              :href="
                dataResponse.pedido.transaccion.transaccion_urlefectybaloto
              "
            >
              Ver cupón de pago
            </a>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 d-flex">
          <div class="container-info-data">
            <h5 class="mb-2">
              <b>Resumen de compra</b>
            </h5>
            <p class="mb-2">
              <b>Subtotal:</b>
              ${{ formatPrice(dataResponse.carrito.carrito_subtotal) }}
            </p>
            <p class="mb-2">
              <b>Envío:</b>
              ${{ formatPrice(dataResponse.carrito.carrito_envio) }}
            </p>
            <p class="mb-4">
              <b>Total:</b>
              ${{ formatPrice(dataResponse.carrito.carrito_total) }}
            </p>
            <h5 class="mb-2">
              <b>Resumen de compra</b>
            </h5>
            <p class="mb-2">
              <b>Método de pago:</b>
              {{ dataResponse.pedido.transaccion.transaccion_metodopago }}
            </p>
            <p class="mb-2">
              <b>Referencia de pago:</b>
              {{ dataResponse.pedido.pedido_reference_sale }}
            </p>
            <p class="mb-0">
              <b>Fecha:</b>
              {{ dataResponse.pedido.pedido_fecha }}
            </p>
          </div>
        </div>
        <div class="col-md-6 d-flex">
          <div class="container-info-data">
            <h5 class="mb-2">
              <b>Datos comprador</b>
            </h5>
            <p class="mb-2">
              {{ dataResponse.carrito.cliente_nombre }}
            </p>
            <p class="mb-2">
              {{ dataResponse.carrito.cliente_correo }}
            </p>
            <p class="mb-2">
              {{ dataResponse.carrito.cliente_barrio }}
            </p>
            <p class="mb-4">
              {{ dataResponse.carrito.cliente_celular }}
            </p>
            <h5 class="mb-2">
              <b>Dirección de envío</b>
            </h5>
            <p class="mb-2">
              {{ dataResponse.carrito.cliente_direccion }}
              {{ dataResponse.carrito.cliente_barrio }}
            </p>
            <p v-if="dataResponse.carrito.observaciones" class="mb-2">
              {{ dataResponse.carrito.observaciones }}
            </p>
            <p class="mb-0">
              {{
                dataResponse.carrito.cliente_ciudad_texto
                  .split(',')[0]
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }},
              {{
                dataResponse.carrito.cliente_ciudad_texto
                  .split(', ')[1]
                  .toLowerCase()
                  .replace(/^\w/, c => c.toUpperCase())
              }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="products.length > 0" class="px-0 col-12">
        <div class="container-info-data">
          <div
            v-for="(product, index) in products"
            :key="'productCart' + index"
            class="container-product-summary"
          >
            <img
              :src="product.producto_imagen"
              :alt="product.producto_descripcion"
              class="img-product"
            />
            <div class="container-info-product">
              <div>
                <p class="mb-2" style="font-size: 18px;">
                  {{
                    product.producto_descripcion
                      .toLowerCase()
                      .replace(/^\w/, d => d.toUpperCase())
                  }}
                </p>
                <p class="mb-2" style="font-size: 14px;">
                  SKU: {{ product.producto_sku }}
                  Talla:
                  {{ product.talla.replace('TALLA ', '') }}
                  -
                  {{ product.color }}
                </p>
              </div>
              <div class="container-price-product">
                <p class="mb-2">
                  <b>${{ formatPrice(product.producto_precio) }}</b>
                </p>
                <p v-if="parseInt(product.producto_precio_antes) > 0">
                  <span class="discount-price">
                    <b>
                      <del>
                        ${{
                          new Intl.NumberFormat('de-DE').format(
                            product.producto_precio_antes,
                          )
                        }}
                      </del>
                    </b>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <raiting-summary
          :reference="dataResponse.pedido.pedido_reference_sale"
        />
      </div>
    </b-col>
  </div>
</template>
<script>
import RaitingSummary from '@/components/Payment/RaitingSummary.vue'
export default {
  components: { RaitingSummary },
  props: {
    dataResponse: {
      type: Object,
      required: true,
      default: () => {},
    },
    products: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    getDataAnalytics() {
      let arrayTemp = []
      this.products.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_descripcion,
          size: product.talla,
          brand: product.etiquetas_compra,
          id: product.producto_sku,
          price: product.producto_precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
  mounted() {
    // let dataCart = this.dataResponse.carrito
    // this.dataLayerPush(dataCart, 'DK-CheckoutPayment', 4)
    // this.dataLayerPush(dataCart, 'DK-Purchase', 5)
  },
  methods: {
    formatPrice(val) {
      return new Intl.NumberFormat('de-DE').format(val)
    },
    dataLayerPush(datos, evento, paso) {
      window.dataLayer.push({
        event: evento,
        step: paso,
        carrito_id: datos.carrito_id,
        ecommerce: {
          currencyCode: 'COP',
          products: this.getDataAnalytics,
          subtotal: datos.carrito_subtotal,
          total: datos.carrito_total,
        },
        profile: {
          email: datos.cliente_correo,
          tipo_doc: datos.tipodoc,
          documento: datos.cliente_documento,
          nombre: datos.cliente_nombre,
          apellidos: datos.cliente_apellidos,
          celular: datos.cliente_celular,
        },
        shipping: {
          direccion: datos.cliente_direccion,
          barrio: datos.cliente_barrio,
          departamento_id: datos.cliente_departamento,
          ciudad_id: datos.cliente_ciudad,
          observaciones: datos.observaciones,
        },
        payment: {
          total: datos.carrito_total,
          paymentMethod: datos.carrito_medio_pago,
          coupon: datos.cupon_codigo,
        },
        value: datos.carrito_total,
      })
    },
  },
}
</script>
<style>
.container-summary-payment {
  background-color: white;
  margin-right: auto;
  margin-left: auto;
  padding: 2rem 3rem;
  max-width: 1200px;
}
.divider-product {
  height: 1px;
  background: #eaf0fc;
  margin-left: 1rem !important;
  max-width: 90% !important;
}
.sub-title {
  font-size: 18px;
}
.text-small {
  font-size: 14px;
  text-align: center;
}
.container-no-pedido {
  border: 1px solid #eeeeee;
  margin: auto;
  padding: 0.5rem 1rem;
  font-weight: bold;
}
.container-info-data {
  background-color: #f7f3f0;
  border-radius: 5px;
  padding: 1.2rem;
  width: 100%;
  margin-bottom: 2rem;
}
.container-product-summary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid #e3e3e3;
}
.container-product-summary:first-child {
  padding-top: 0;
}
.container-product-summary:last-child {
  padding-bottom: 0;
  border: none;
}
.container-product-summary .img-product {
  max-width: 60px;
  margin-bottom: auto;
}
.container-product-summary .container-info-product {
  flex: 1 0 0%;
  padding-left: 1.3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.container-product-summary .container-price-product {
  font-size: 18px;
  text-align: center;
}
.discount-price {
  color: #adb2b7;
}
.container-stados-summary-payment {
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}
.container-stados-summary-payment::after {
  content: '';
  position: absolute;
  border: 2px dashed #eeeeee;
  width: 90%;
  left: 5%;
  top: 35px;
}
.container-stados-summary-payment .container-step {
  background-color: #bfc6ce;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 70px;
  min-height: 70px;
  width: 70px;
  height: 70px;
  position: relative;
  z-index: 1;
}
.container-stados-summary-payment .container-step-select {
  background-color: #654062;
}
.container-stados-summary-payment .container-name-step {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 0.3rem;
}
.container-stados-summary-payment .container-name-step .name-step {
  text-align: center;
  min-width: 70px;
}
.container-stados-summary-payment .container-name-step .name-step-select {
  font-weight: bold;
}
@media (max-width: 500px) {
  .container-product-summary .img-product {
    max-width: 80px;
    margin: auto;
  }
  .container-summary-payment {
    padding: 2rem 1rem;
  }
}
@media (max-width: 330px) {
  .container-stados-summary-payment {
    display: none;
  }
}
</style>
